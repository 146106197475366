import React, { useState, useEffect } from "react";
import JobCard from "./JobCard";
import Loading from "../jobs/Loading";
import Error from "../jobs/Error";
// import "../../css/jobs_list_display.css";
import "../../css/JobsList.css";
import Pagination from "../../paginations/Pagination";
import JobsNotFound from "./JobsNotFound";
import Navbar from "../../components/inc/Navbar";
import SearchForm from "../landing/SearchForm";
import { useLocation } from "react-router-dom";
import Footer from "../../components/inc/Footer";

// function JobsList({ searchTerm, category }) {
  function JobsList() {


    const location = useLocation(); // Initialize useLocation
    const { searchTerm: initialSearchTerm = "", category: initialCategory = "" } = location.state || {}; // Destructure state

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  // const [currentJobs, setCurrentJobs] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const companiesPerPage = 3;

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm); // Initialize from route state
  const [category, setCategory] = useState(initialCategory); // Initialize from route state


    // const [searchTerm, setSearchTerm] = useState("");
    // const [category, setCategory] = useState("");

  // Moved outside of useEffect for reuse in retry
  const fetchJobs = async () => {
    setLoading(true);
    try {
      // For Server API
      const response = await fetch(
        "https://jobsfair.info/api/api/jobs/get_jobs.php"
      );

      // // For Local API
      // const response = await fetch(
      //   `http://localhost/web/react/www.jobsfair.info/api/api/jobs/get_jobs.php`
      // );

      if (!response.ok) {
        setError(
          "We are unable to load the jobs at this moment. Please try again later."
        );
        return;
      }
      const data = await response.json();
      if (data.status) {
        setCompanies(data.data || []);
      } else {
        // setError("We are experiencing data issues. Please check back soon.");
        // setError(data.message);
        <JobsNotFound />;
      }
    } catch (error) {
        setError("An API Network error. Please check your connection.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  // Filter companies based on search term and category
  const filteredCompanies = companies.filter((company) => {
    // Split the search term into individual words and make them lowercase
    const searchWords = searchTerm.toLowerCase().split(" ");

    // Check if company name matches any of the search words
    const companyMatchesSearch = searchWords.some((word) =>
      company.company_name.toLowerCase().includes(word)
    );

    // Check if any job in the company matches any of the search words or the category
    const jobsMatch = company.jobs.some((job) => {
      const matchesSearch = searchWords.some((word) =>
        job.title.toLowerCase().includes(word)
      );
      const matchesCategory =
        !category || job.category.toLowerCase() === category.toLowerCase();
      return matchesSearch && matchesCategory;
    });

    // Return companies where either the company name matches or one of its jobs matches
    return companyMatchesSearch || jobsMatch;
  });

  // Pagination
  const endIndex = currentPage * companiesPerPage;
  const startIndex = endIndex - companiesPerPage;
  const currentCompanies = filteredCompanies.slice(startIndex, endIndex);

  // Render jobs for a company
  function renderJobs(company) {
    if (company.jobs && company.jobs.length > 0) {
      return company.jobs.map((job) => (
        <JobCard key={job.job_id} jobList={job} />
      ));
    } else {
      return (
        <div>
          <JobsNotFound />
        </div>
      );
    }
  }

  // Render company list
  function renderCompanies(companies) {
    return companies.length > 0 ? (
      companies.map((company) => (
        <div className="jobs-main-container mycard" key={company.company_id}>
          <h2>Posted by: {company.company_name}</h2>
          {/* <h2>Posted by: {company.company_name || "Unknown Company"}</h2> */}
          <div className="jobs-info">{renderJobs(company)}</div>
        </div>
      ))
    ) : (
      // <p>No companies available</p>
      <div>
        <JobsNotFound />
      </div>
    );
  }

  const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

  if (loading) return <Loading />;
  if (error) return <Error message={error} retryHandler={fetchJobs} />;

  // Handle the case when the query returns an empty array because of no jobs with future deadlines
  if (companies && companies.length === 0 && !error) {
    return (
      <div className="jobs-list-container">
        <div className="side-panel1">{/* LeftSidePane can go here */}</div>

        <div className="jobs-main-container">
          <div className="jobs-container">
            {/* <p>No jobs available at this time.</p> */}
            <div>
              <JobsNotFound />
            </div>
          </div>
        </div>

        <div className="side-panel2">{/* RightSidePane can go here */}</div>
      </div>
    );
  }
  const handleSearch = (searchQuery, selectedCategory) => {
    setSearchTerm(searchQuery);
    setCategory(selectedCategory);
  };

  return (
    <div>
      <Navbar />
      {/* <hr></hr> */}
      <div className="searches">
        <SearchForm onSearch={handleSearch} />
      </div>
     
    <div className="jobs-list-container">
      <div className="jobs-side-panel1">{/* LeftSidePane can go here */}</div>

      <div className="jobs-main-container">
        <div className="jobs-container">
          <div className="jobs-info">{renderCompanies(currentCompanies)}</div>

          <div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage} // Crucial for page change
            />
          </div>
        </div>
      </div>

      <div className="jobs-side-panel2">{/* RightSidePane can go here */}</div>
    </div>
    <Footer />
    </div>
  );
}

export default JobsList;
