import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/Navbar.css";
import logo from "../../assets/logo.ico";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div>
      <div className="container-fluid">
        <div className="left-nav">
          <p></p>
        </div>
        <div className="center-nav">
          <nav>
            <div className="logowrapper">
              <img src={logo} alt="Logo" />
              <h4>
                <Link to="/">Jobsfair.info</Link>
              </h4>
            </div>

            {/* Add the active class to Nav-Links when isMobileMenuOpen is true */}
            <div className={`Nav-Links ${isMobileMenuOpen ? "active" : ""}`}>
              <ul className="navigations">
                <li className={isActive("/") ? "active" : ""}>
                  <Link to="/">Home</Link>
                </li>
                <li className={isActive("/pages/jobs") ? "active" : ""}>
                  <Link to="/pages/jobs">Jobs</Link>
                </li>
                
                {/* <li className={isActive("/pages/auth/training") ? "active" : ""}>
                  <Link to="/pages/auth/training">Training</Link>
                </li> */}
                <li className={isActive("/pages/scholarships") ? "active" : ""}>
                  <Link to="/pages/scholarships">Scholarships</Link>
                </li>
                <li className={isActive("/pages/subscriptions") ? "active" : ""}>
                  <Link to="/pages/subscriptions">Subscribe Now</Link>
                </li>
              </ul>
            </div>

            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              &#9776;
            </button>
          </nav>
        </div>
        <div className="right-nav">
          <p></p>
        </div>
      </div>
       <hr className="separator"></hr>

    </div>
  );
}

export default Navbar;