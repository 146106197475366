import React from "react";
import "../../css/JobsNotFound.css";

function JobsNotFound() {
  return (
    <div className="jobs-not-found">
      <h2>No jobs found</h2>
      <p>
        We couldn't find any jobs that match your criteria. Please try adjusting
        your search or category filters.
      </p>
    </div>
  );
}

export default JobsNotFound;
