import React, { useState, useEffect } from "react";
import Header from "../Header";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import "../css1/SendNotifications.css";

function SendNotification() {
  const [jobs, setJobs] = useState([]); // State to store job list
  const [jobId, setJobId] = useState(""); // State for selected job ID
  const [subject, setSubject] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [plainTextContent, setPlainTextContent] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  // Fetch jobs from the backend on component mount
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch("fetch_jobs.php"); // Adjust the PHP file as needed
        const data = await response.json();
        setJobs(data); // Assuming the data is an array of job objects
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("ssend_notification.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          job_id: jobId, // Include selected job ID in the body
          subject,
          htmlContent,
          plainTextContent,
        }),
      });

      const result = await response.text();
      setFeedbackMessage(result); // Update feedback message with the server response
    } catch (error) {
      setFeedbackMessage("An error occurred while sending the notification.");
    }
  };

  return (
    <div>
      <Header />
      <div className="notification-container">
        <AdminSideBarMenu />
        <div className="notification-page">
          <h1>Send Notification to Subscribers</h1>
          <form onSubmit={handleSubmit}>
            <div className="notification-group">
              <label htmlFor="jobId" className="form-label">
                Select Job:
              </label>
              <div className="job-selection">
                <select
                  id="jobId"
                  name="jobId"
                  value={jobId}
                  onChange={(e) => setJobId(e.target.value)}
                  required
                >
                  <option value="">-- Select a Job --</option>
                  {jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="notification-group">
              <label htmlFor="subject" className="form-label">
                Email Subject:
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                className="input-field"
              />
            </div>

            <div className="notification-group">
              <label htmlFor="htmlContent" className="form-label">
                HTML Message:
              </label>
              <textarea
                id="htmlContent"
                name="htmlContent"
                value={htmlContent}
                onChange={(e) => setHtmlContent(e.target.value)}
                required
                className="input-field"
              />
            </div>

            <div className="notification-group">
              <label htmlFor="plainTextContent" className="form-label">
                Plain Text Message:
              </label>
              <textarea
                id="plainTextContent"
                name="plainTextContent"
                value={plainTextContent}
                onChange={(e) => setPlainTextContent(e.target.value)}
                required
                className="input-field"
              />
            </div>

            <button type="submit">Send Notification</button>
          </form>

          {feedbackMessage && (
            <p className="feedback-message">{feedbackMessage}</p>
          )}
          {/* Display feedback message */}
        </div>
      </div>
    </div>
  );
}

export default SendNotification;
