import React, { useState, useEffect } from "react";
import ScholarshipCard from "./ScholarshipCard";
// import Navbar from "../../components/inc/Navbar";
import Loading from "../jobs/Loading";
// import LeftSidePane from "../../sidebar/LeftSidePane";
// import RightSidePane from "../../sidebar/RightSidePane";

import "../../css/ScholarshipsList.css";

const ScholarshipsList = () => {
  const [loading, setLoading] = useState(true);
  const [scholarships, setScholarships] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchScholarshipsData = async () => {
      try {
        // For local testing
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/scholarships/get_scholarships.php`
        // );

        const response = await fetch(
          `https://jobsfair.info/api/api/scholarships/get_scholarships.php`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const result = await response.json();

        if (result.success) {
          setScholarships(result.data);
        } else {
          setError("No scholarships available at the moment.");
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
        setError("An API Failed to load scholarships. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchScholarshipsData();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="main-scholarship">
      {/* <Navbar /> */}
      <div className="container-scholarship">
        <div className="ss-side-panel1">
          {/* <h4>Refine the List with Filters</h4> */}
          {/* <LeftSidePane /> */}
        </div>

        <div className="body-scholarship">
          <div className="ss-session-message">
            {error && <p className="ss-error-message">{error}</p>}
          </div>

          <div className="scholarships-list">
            <h1>Scholarships</h1>
            {scholarships.length > 0
              ? scholarships.map((scholarship) => (
                  <ScholarshipCard
                    key={scholarship.id}
                    scholarship={scholarship}
                  />
                ))
              : !error && <p>No scholarships available</p>}
          </div>
        </div>
        <div className="ss-side-panel2">
          {/* <h4>Refine the List with Filters</h4> */}
          {/* <RightSidePane /> */}
        </div>
      </div>
    </div>
  );
};

export default ScholarshipsList;
