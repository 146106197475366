import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../css/JobCard.css";
// import "../../css/JobCard1.css";
import parse from "html-react-parser";

function JobCard({ jobList }) {
  // Destructure properties from jobList for easier access
  const {
    job_id: jobId, // Rename job_id to id
    title,
    updated_at,
    deadline,
    requirement,
    description,
  } = jobList;

  // State to track whether the description is expanded
  const [isExpanded, setIsExpanded] = useState(false);

  // Define the maximum number of words to show initially
  const maxWords = 50;

  // Function to toggle between full and truncated description
  const toggleMoreOrLess = () => {
    setIsExpanded(!isExpanded);
  };

  // Utility function to limit text to maxWords
  const truncateText = (text) => {
    if (!text) return ""; // Handle null or undefined
    const words = text.split(" ");
    return words.length > maxWords
      ? words.slice(0, maxWords).join(" ") + "..."
      : text;
  };

  // Function to determine status based on deadline
  const getStatus = () => {
    const currentDate = new Date(); // Get the current date
    const deadlineDate = new Date(deadline); // Convert the deadline string to a Date object

    return deadlineDate > currentDate ? "Active" : "Expired"; // Determine status
  };

  return (
    <div>
      <hr className="border-custom" />
      <div className="overview-jobs">
        <p>
          {" "}
          Job Title: <strong>{title}</strong>
        </p>
        <div className="updated-deadline">
          <div className="updated-section">
            <p>Updated At: {updated_at}</p>
          </div>
          <div className="deadline-section">
            <p>Deadline: {deadline}</p>
          </div>
          <div
            className={`status-section ${
              getStatus() === "active" ? "active-status" : "expired-status"
            }`}
          >
            <p>Status: {getStatus()}</p> {/* Display job status */}
          </div>
        </div>

        {/* <p>Requirement: {requirement}</p> */}
        <div className="see-more-less">
          <div className="parse-see-more-less">
            <strong>Requirement: </strong>
            {isExpanded ? parse(requirement) : parse(truncateText(requirement))}
            {description.split(" ").length > maxWords && (
              <button
                onClick={toggleMoreOrLess}
                className={isExpanded ? "see-less" : "see-more"} // Apply specific class based on state
              >
                {isExpanded ? "See Less" : "See More"}
              </button>
            )}
          </div>
        </div>

        <div className="posted-updated-date"></div>
        <hr />
        <div className="see-more-less">
          <div className="parse-see-more-less">
            <strong>Description: </strong>
            {isExpanded ? parse(description) : parse(truncateText(description))}
            {description.split(" ").length > maxWords && (
              <button
                onClick={toggleMoreOrLess}
                className={isExpanded ? "see-less" : "see-more"} // Apply specific class based on state
              >
                {isExpanded ? "See Less" : "See More"}
              </button>
            )}
          </div>
        </div>
        <div className="see-more-details">
          <Link to={`/pages/jobs/${jobId}/details`}>
                  Click here for Deadlines and View Details
                  <i className="fas fa-arrow-right"></i>
                </Link>
        </div>

        {/* <div className="button-detail-apply">
          <button className="btn-details">
            <Link to={`pages/jobs/${id}/details`}>See Details</Link>
          </button>
        </div> */}

      </div>
    </div>
  );
}

export default JobCard;
