import { useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

import parse from "html-react-parser";

function JobRow({ job, companyName, company }) {
  const [expanded, setExpanded] = useState(false);

  const toggleDetails = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <tr>
        <td>{job.job_id}</td>
        <td>{companyName}</td>
        <td>{job.title}</td>
        <td className="actions">
          <button
            onClick={toggleDetails}
            className="details-toggle-btn"
            aria-expanded={expanded}
          >
            {expanded ? "Hide Details" : "Show Details"}
          </button>
        </td>
      </tr>

      {expanded && (
        <tr className="details-row">
          <td colSpan="4">
            <table className="jobs-details-table">
              <tbody>
                <tr>
                  <th>Job Description:</th>
                  <td>{parse(job.description) || "N/A"}</td>
                </tr>
                <tr>
                  <th>Requirements:</th>
                  <td>{parse(job.requirement) || "N/A"}</td>
                </tr>
                <tr>
                  <th>Experience:</th>
                  <td>{parse(job.experiences) || "N/A"}</td>
                </tr>
                <tr>
                  <th>Application URL:</th>
                  <td>
                    {job.applyUrl ? (
                      <a
                        href={job.applyUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apply Here
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Updated At:</th>
                  <td>{new Date(job.updated_at).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <th>Deadline:</th>
                  <td>{new Date(job.deadline).toLocaleDateString()}</td>
                </tr>
                <tr className="actions-row">
                  <th className="actions">
                    <Link
                      to={`/back/jobs/edit-job/${job.job_id}`}
                      className="edit-link"
                    >
                      Edit
                    </Link>{" "}
                  </th>
                  <th className="actions">
                    <Link
                      to={`/back/jobs/delete-job/${job.job_id}`}
                      className="delete-link"
                    >
                      Delete
                    </Link>
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}

export default JobRow;
