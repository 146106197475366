import React, { useState, useEffect } from "react";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import "../css1/ListOfJobs.css";
import { Link } from "react-router-dom";
import Header from "../Header";
import JobRow from "./JobRow";

// import parse from "html-react-parser";

function ListOfJobs() {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/jobs/get_jobs.php`
        // );

        const response = await fetch(
          `https://jobsfair.info/api/api/jobs/get_jobs.php`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status) {
          setCompanies(data.data || []);
        } 
        // if (Array.isArray(data.data)) {
        //   setCompanies(data.data);
        // } else {
        //   throw new Error("Unexpected data format");
        // }

      } catch (err) {
        setError("Failed to load jobs. Please try again later.");
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="jobs-list-page">
      <Header />
      <div className="back-jobs-container">
        <AdminSideBarMenu />
        <div className="jobs-content">
          <div className="jobs-header">
            <h1>Manage Jobs</h1>
            <Link to="/back/jobs/add-jobs" className="add-job-btn">
              Add New Job
            </Link>
          </div>

          <div className="table-container">
            {error && <p className="error-message">{error}</p>}
            {companies.length > 0 ? (
              <table className="job-list-table">
                <thead>
                  <tr>
                    <th>Job ID</th>
                    <th>Company</th>
                    <th>Title</th>
                    {/* <th>Description</th>
                    <th>Requirements</th>
                    <th>Experience</th>
                    <th>Apply URL</th>
                    <th>Updated</th>
                    <th>Deadline</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((company) =>
                    company.jobs.map((job) => (
                      <JobRow
                        key={job.job_id}
                        job={job}
                        companyName={company.company_name}
                      />
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <p className="no-jobs-message">
                No jobs available. Please add a new job.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListOfJobs;
