import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

// import Login from "./pages/auths/Login";

import Whoops404 from "./pages/Whoops404";
import JobDetails from "./pages/jobs/JobDetails";
import AboutUs from "./pages/landing/AboutUs";
// import FeedbackForm from "./back/pages/FeedbackForm";
import FeedbackForm from "./pages/feedback/FeedbackForm";
// import Dashboard from "./back/Dashboard";

// import SidebarMenu from "./back/SidebarMenu";

// import Jobs from "./back/jobs/Jobs";

import JobsList from "./pages/jobs/JobsList";

import ListOfJobs from "./back/jobs/ListOfJobs";

import AddJob from "./back/jobs/AddJob";
import CompaniesList from "./back/company/CompaniesList";
import AddCompany from "./back/company/AddCompany";
import LoginUser from "./back/auth/LoginUser";
import ProtectedRoute from "./back/auth/ProtectedRoute";
import EditJobItem from "./back/jobs/EditJobItem";
import DeleteJobItem from "./back/jobs/DeleteJobItem";
import EditCompany from "./back/company/EditCompany";
import DeleteCompanyItem from "./back/company/DeleteCompanyItem";
import { AuthProvider } from "./back/auth/AuthContext";
// import ScholarshipsList from "./pages/scholarships/ScholarshipsList";
// import ScholarshipCard from "./pages/scholarships/ScholarshipCard";
import ScholarshipDetails from "./pages/scholarships/ScholarshipDetails";
import AddScholarship from "./back/scholarships/AddScholarship";

import AddUser from "./back/users/AddUser";
import HomeScholarships from "./pages/scholarships/HomeScholarships";
import Subscriptions from "./pages/subscriptions/Subscriptions";

import SendNotification from "./back/notification/SendNotification";
import DefaultPage from "./DefaultPage";
import AdminDashboard from "./back/admin/AdminDashboard";

import UserDashboard from "./back/user_dashboard/UserDashboard";
import UserList from "./back/users/UserList";
import EditUser from "./back/users/EditUser";
import DeleteUser from "./back/users/DeleteUser";
// import AdminSidebarMenu from "./back/admin/AdminSidebarMenu1";
// import Dashboard from "./back/Dashboard1 copy";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/default", element: <DefaultPage /> },
  // { path: "/admin", element: <AdminSidebarMenu /> },

  { path: "*", element: <Whoops404 /> },
  // { path: "/pages/auths/login", element: <Login /> },
  { path: "/pages/subscriptions", element: <Subscriptions /> },

  { path: "/pages/landing/aboutus", element: <AboutUs /> },
  { path: "/pages/jobs", element: <JobsList /> },
  { path: "/pages/jobs/:jobId/details", element: <JobDetails /> },
  // { path: "/pages/jobs/jobs", element: <Jobs /> }, // Updated route

  //   scholarships
  // { path: "/pages/scholarships", element: <ScholarshipsList /> }, // Updated route
  { path: "/pages/scholarships", element: <HomeScholarships /> }, // Updated route
  { path: "/pages/scholarships/:id/details", element: <ScholarshipDetails /> }, // Updated route

  //   back scholar
  { path: "/back/scholarships/add", element: <AddScholarship /> }, // Updated route

  { path: "*", element: <Whoops404 /> },

  // Protected routes
  {
    path: "/back/1admin/adashboard",
    element: <ProtectedRoute element={<AdminDashboard />} />,
  },

  {
    path: "/back/jobs",
    element: <ProtectedRoute element={<ListOfJobs />} />,
  },
  {
    path: "/back/jobs/add-jobs",
    element: <ProtectedRoute element={<AddJob />} />,
  },
  {
    path: "/back/jobs/edit-job/:jobId",
    element: <ProtectedRoute element={<EditJobItem />} />,
  },
  {
    path: "/back/jobs/delete-job/:jobId",
    element: <ProtectedRoute element={<DeleteJobItem />} />,
  },
  {
    path: "/back/company/companies",
    element: <ProtectedRoute element={<CompaniesList />} />,
  },
  {
    path: "/back/company/add-company",
    element: <ProtectedRoute element={<AddCompany />} />,
  },
  {
    path: "/back/company/edit-company/:companyId",
    element: <ProtectedRoute element={<EditCompany />} />,
  },
  {
    path: "/back/company/delete-company/:companyId",
    element: <ProtectedRoute element={<DeleteCompanyItem />} />,
  },
  // users
  {
    path: "/back/users/add-user",
    element: <ProtectedRoute element={<AddUser />} />,
  },
  {
    path: "/back/users/users-list",
    element: <ProtectedRoute element={<UserList />} />,
  },
  {
    path: "/back/users/edit-user/:userId",
    element: <ProtectedRoute element={<EditUser />} />,
  },
  {
    path: "/back/users/delete-user/:userId",
    element: <ProtectedRoute element={<DeleteUser />} />,
  },
  {
    path: "/back/notification/sendNotification",
    element: <ProtectedRoute element={<SendNotification />} />,
  },

  { path: "/back/auth/login", element: <LoginUser /> },
  { path: "/pages/feedback", element: <FeedbackForm /> },

  // User Page
  {
    path: "/back/1user/udashboard",
    element: <ProtectedRoute element={<UserDashboard />} />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
