import { useEffect, useState } from "react";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Header";
import "../css1/UsersReg.css"; // Import the CSS file

function DeleteUser() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    username: "",
    status: "active",
    role: "user",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Fetch user data to display before deleting
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://jobsfair.info/api/api/user/get_user.php?userId=${userId}`
        );

        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/user/get_user.php?userId=${userId}`
        // // );
        const data = await response.json();
        if (data.status === "success") {
          setUser(data.data); // Populate user data
        } else {
          setError("User not found or API error");
        }
      } catch (error) {
        setError("Error fetching user data from API");
        setError("Error fetching user data from API");
      }
    };

    fetchUserData();
  }, [userId]);

  // Handle delete action
  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) {
      return; // Exit if user cancels the deletion
    }

    try {
      const response = await fetch(
        `https://jobsfair.info/api/api/user/delete_user.php?userId=${userId}`,{

      // const response = await fetch(
      //   `http://localhost/web/react/www.jobsfair.info/api/api/user/delete_user.php?userId=${userId}`,
      //   {
          method: "POST", // Corrected to DELETE method
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      if (!response.ok) {
        // Check for non-2xx status codes
        const errorData = await response.json(); //Attempt to parse error response
        const errorMessage =
          errorData.message || `HTTP error! status: ${response.status}`;
        throw new Error(errorMessage);
      }
      const result = await response.json();
      if (result.success) {
        setSuccess("User deleted successfully!");
        setTimeout(() => {
          navigate("/back/users/users-list"); // Redirect to the user list page after deletion
        }, 2000); // Delay to let user see the success message
      } else {
        setError(result.message || "An error occurred");
      }
    } catch (error) {
      setError(`Error fetching user data from API: ${error.message}`);
    }
  };

  return (
    <div>
      <Header />
      <div className="users-container">
        <AdminSideBarMenu />
        <div className="Users-page">
          <h1>Delete User</h1>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>

          <div className="registration-card" tabIndex="1">
            <h3>Are you sure you want to delete the following user?</h3>
            <p>
              <strong>Name:</strong> {user.username}
            </p>
            <p>
              <strong>Email:</strong> {user.email}
            </p>

            <button onClick={handleDelete} className="delete-button">
              Delete User
            </button>
            <button
              onClick={() => navigate("/back/users/users-list")}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteUser;
