import React, { useState, useEffect } from "react";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import Header from "../Header";
import "../css1/UserList.css";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    fetchUsers();
  }, []);

  // Function to fetch users from the API
  const fetchUsers = async () => {
    try {
      const response = await fetch(
        "https://jobsfair.info/api/api/user/get_users.php"
      );
      // const response = await fetch(
      //   "http://localhost/web/react/www.jobsfair.info/api/api/user/get_users.php"
      // );

      if (!response.ok) {
        throw new Error("Failed to fetch users.");
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setError(error.message); // Set error state
    }
  };

  return (
    <div className="user-list-div">
      <Header />
      <div className="user-content-div">
        <AdminSideBarMenu />
        <div className="user-list-table">
          <h2>User List</h2>
          {error && <p className="error">{error}</p>}{" "}
          {/* Display error if any */}
          <div className="users-table-header">
            <h1>All Users List</h1>
            <Link to="/back/users/add-user" className="add-user-btn">
              Add New User
            </Link>
          </div>
          <div className="users-table-list">
            <table border="1" className="user-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.role}</td>
                      <td className="actions">
                        <Link
                          to={`/back/users/edit-user/${user.id}`}
                          className="edit-link"
                        >
                          Edit
                        </Link>{" "}
                        |{" "}
                        <Link
                          to={`/back/users/delete-user/${user.id}`}
                          className="delete-link"
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No users found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
