import React from "react";
import { useNavigate } from "react-router-dom";

const DefaultPage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div
      className="default-container"
      style={{
        textAlign: "center",
        padding: "20px",
        justifyContent: "center",
        display: "block",
        flexDirection: "space-around",
        alignItems: "center",
      }}
    >
      <div style={{ justifyItems: "center" }}>
        <h1>Welcome to Our Site</h1>
        <p>You don’t have access to the admin or user dashboards.</p>
        <p>Please explore our homepage or log in to access your dashboard.</p>
      </div>

      <button
        onClick={() => handleNavigation("/")}
        style={{ marginRight: "10px" }}
      >
        Go to Homepage
      </button>
      <button onClick={() => handleNavigation("/back/auth/login")}>
        Login
      </button>
    </div>
  );
};

export default DefaultPage;
