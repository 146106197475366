import React, { useEffect, useState } from "react";
import "../../css/Subscriptions.css";
import Navbar from "../../components/inc/Navbar";
import SocialMediaIcons from "../../components/inc/SocialMediaIcons";
import profileIcon from "../../assets/profileIcon.png";
import Footer from "../../components/inc/Footer";

function Subscriptions() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    subscription_type: "", // This is correctly initialized as an empty string
  });
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);

  useEffect(() => {
    document.title = "Subscriptions - jobsfair.info";
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, subscription_type } = formData;
    if (!email) {
      setMessage("Email is required.");
      return;
    }
    if (!validateEmail(email)) {
      setMessage("Invalid email format.");
      return;
    }
    if (!subscription_type) {
      setMessage("Subscription type is required.");
      return;
    }

    try {
      // const response = await fetch(
      //   "http://localhost/web/react/www.jobsfair.info/api/api/subscriptions/SendSubscriptions.php",

        const response = await fetch(
          "https://jobsfair.info/api/api/subscriptions/SendSubscriptions.php",

        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok && result.status === "success") {
        setMessage(result.message);
        setIsSuccess(true);
        setSubscribed(true);
        setSubmittedData(formData); // Save submitted data
        setFormData({ username: "", email: "", subscription_type: "" });
      } else {
        setMessage(result.message || "Subscription failed.");
        setIsSuccess(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An API error occurred. Please try again.");
      setIsSuccess(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="loginpage">
        <div className="login-main-sidebar">
          <div className="myLoginCard">
            <h2>Why Subscribe?</h2>
            <ul className="login-advantages">
              <li>Receive exclusive updates and content.</li>
              <li>Access personalized recommendations.</li>
            </ul>
            <SocialMediaIcons />
          </div>
          {/* send */}
          {subscribed && submittedData && (
            <div className="myLoginCard">
              <div className="thankyou-subscribe">
                <h1>Hello {submittedData.username || "Subscriber"}!</h1>
                <p>
                  We will send updates to your email:{" "}
                  <strong>{submittedData.email}</strong>
                </p>
              </div>
            </div>
          )}
        </div>
        {/* send */}
        <div className="login-right-sidebar">
          <div className="myLoginCard">
            <h2>Subscribe Now!</h2>
            <form onSubmit={handleSubmit}>
              <img src={profileIcon} alt="Profile" className="ProfileImage" />
              <input
                type="text"
                name="username"
                placeholder="Username (optional)"
                value={formData.username}
                onChange={handleInputChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email (required)"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
              <select
                name="subscription_type"
                value={formData.subscription_type}
                onChange={handleInputChange}
              >
                <option value="">Select Subscription</option>
                <option value="software-engineering">
                  Software Engineering
                </option>
                <option value="data-science">Data Science</option>
                <option value="web-development">Web Development</option>
              </select>
              <div className="subscribe-btn"> 
              <button type="submit">Subscribe</button>
              </div>
             
              {message && (
                <p
                  className={`form-message ${isSuccess ? "success" : "error"}`}
                >
                  {message}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Subscriptions;
