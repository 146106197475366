import React, { useState, useEffect } from "react";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import Header from "../Header";
import "../css1/AddJob.css";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const AddJob = () => {
  const [formData, setFormData] = useState({
    company_id: "",
    category: "",
    title: "",
    updated_at: "",
    deadline: "",
    description: "",
    requirement: "",
    experiences: "",
    responsibility: "",
    qualification: "",
    applyUrl: "",
    department: "",
    location: "",
  });

  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/company/get_companies.php`
        // );
        const response = await fetch(
          `https://jobsfair.info/api/api/company/get_companies.php`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch companies");
        }
        const data = await response.json();
        setCompanies(data.data);
      } catch (error) {
        setError("An API error occurred while fetching the data");
      }
    };

    fetchCompanies();
  }, []);

  const handleCkeditorChange = (name) => (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({ ...prevData, [name]: data }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFocus = (event) => {
    event.target.style.borderColor = "#007BFF";
    event.target.style.boxShadow = "0 0 8px rgba(0, 123, 255, 0.5)";
    event.target.style.transform = "scale(1.02)";
  };

  const handleBlur = (event) => {
    event.target.style.borderColor = "#ccc";
    event.target.style.boxShadow = "0 0 4px rgba(0, 0, 0, 0.2)";
    event.target.style.transform = "scale(1)";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation
    if (
      !formData.company_id ||
      !formData.title ||
      !formData.deadline ||
      !formData.requirement ||
      !formData.description
    ) {
      setError("Please fill in all required fields.");
      return;
    }
    try {
      const response = await fetch(
        `https://jobsfair.info/api/api/jobs/add_job.php`,
        {
      // const response = await fetch(
      //   `http://localhost/web/react/www.jobsfair.info/api/api/jobs/add_job.php`,
      //   {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("Response status:", response.status); // Check response status
      const result = await response.json();
      console.log("Result:", result); // Log backend response
      console.log("Form data:", formData);

      if (result.success) {
        setSuccess("Job added successfully!");
        alert("Job added successfully");
      } else {
        setError(result.message || "An error occurred");
        throw new Error("Failed to add job");
      }
    } catch (error) {
      console.log(error); // Log the error for debugging
      alert("Error: Maybe Server is down or unreachable:.");
      setError("An API error occurred while submitting the form.");
    }
  };

  return (
    <div>
      <Header />
      <div className="add-job-page">
        <AdminSideBarMenu />
        <div className="add-job-container">
          <h1>Add New Job</h1>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="company_id">Company:</label>
                <select
                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                >
                  <option value="" disabled>
                    Select a company
                  </option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="title">Job Title:</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="category">Category:</label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="department">Department:</label>
                <input
                  type="text"
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="updated_at">Updated At:</label>
                <input
                  type="date"
                  id="updated_at"
                  name="updated_at"
                  value={formData.updated_at}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="deadline">Deadline:</label>
                <input
                  type="date"
                  id="deadline"
                  name="deadline"
                  value={formData.deadline}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
              </div>
            </div>

            {/* CKEditor for Description */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.description}
                  onChange={handleCkeditorChange("description")}
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  config={{
                    placeholder: "Enter job description...",
                  }}
                />
              </div>
            </div>

            {/* CKEditor for Requirement */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="requirement">Requirement:</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.requirement}
                  onChange={handleCkeditorChange("requirement")}
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  config={{
                    placeholder: "Enter job requirements...",
                  }}
                />
              </div>
            </div>

            {/* CKEditor for Experiences */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="experiences">Experiences:</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.experiences}
                  onChange={handleCkeditorChange("experiences")}
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  config={{
                    placeholder: "Enter required experiences...",
                  }}
                />
              </div>
            </div>

            {/* CKEditor for Responsibility */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="responsibility">Responsibility:</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.responsibility}
                  onChange={handleCkeditorChange("responsibility")}
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  config={{
                    placeholder: "Enter job responsibilities...",
                  }}
                />
              </div>
            </div>

            {/* Other fields */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="qualification">Qualification:</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.qualification}
                  onChange={handleCkeditorChange("qualification")}
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  config={{
                    placeholder: "Enter qualifications...",
                  }}
                />
              </div>
            </div>

            {/* Other fields */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="qualification">Location:</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.location}
                  onChange={handleCkeditorChange("location")}
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  config={{
                    placeholder: "Enter Locations...",
                  }}
                />
              </div>
            </div>
            {/* 
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="location">Job Location:</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
              </div>
            </div> */}

            <div className="form-row">
              <div className="form-group full-width">
                <label htmlFor="applyUrl">Apply URL:</label>
                <input
                  type="text"
                  id="applyUrl"
                  name="applyUrl"
                  value={formData.applyUrl}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddJob;
