import React, { useState, useEffect } from "react";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import Header from "../Header";
import "../css1/CompaniesList.css";
import { Link } from "react-router-dom";
import CompanyRow from "./CompanyRow";
import ErrorMessage from "./ErrorMessage";

function CompaniesList() {
  const [companyList, setCompanyList] = useState([]);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/company/get_companies.php`
        // );

        const response = await fetch(
          "https://jobsfair.info/api/api/company/get_companies.php"
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === "success") {
          setCompanyList(data.data);
        } else {
          throw new Error("Unexpected data format");
        }
      } catch (err) {
        setError(
          err.message || "Failed to fetch company data. Please try again later."
        );
      } finally {
        setLoadingCompany(false);
      }
    };

    fetchCompanies();
  }, []);

  if (loadingCompany) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="companies-list-page">
      <Header />
      <div className="container-page">
        <AdminSideBarMenu />
        <div className="list-of-companies">
          {error && <ErrorMessage message={error} />}
          {/* Add New Company Button */}
          <div className="company-header">
            <h1>All Company Lists</h1>
            <Link to="/back/company/add-company" className="add-company-btn">
              Add New Company
            </Link>
          </div>

          <div className="table-container">
            <table className="companies-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companyList.map((company) => (
                  <CompanyRow key={company.id} company={company} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompaniesList;
