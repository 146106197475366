import Loading from "../../pages/jobs/Loading";
import React, { createContext, useState, useEffect, useContext } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const setSession = (user, token) => {
  localStorage.setItem("isAuthenticated", "true");
  localStorage.setItem("currentUser", JSON.stringify(user));
  localStorage.setItem("token", token);
};

const clearSession = () => {
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
};

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState("guest");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    const storedAuthStatus = localStorage.getItem("isAuthenticated");
    const token = localStorage.getItem("token");

    if (storedUser && storedAuthStatus === "true" && token) {
      setIsAuthenticated(true);
      const user = JSON.parse(storedUser);
      setCurrentUser(user);
      setUserRole(user.role || "guest");
    } else {
      setIsAuthenticated(false);
      setUserRole("guest");
    }
    setLoading(false); // Loading finished
  }, []);

  const loginAuth = async (email, password) => {
    if (!email || !password) {
      alert("Email and password are required.");
      setError("Email and password are required.");
      return false;
      // return alert("Email and password are required.");
    }

    try {
      setLoading(true); // Start loading before fetch
      // const response = await fetch(
      //   `http://localhost/web/react/www.jobsfair.info/api/api/user/login.php`,
      //   {
          const response = await fetch(
            "https://jobsfair.info/api/api/user/login.php",
            {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
        setError(`Error: ${errorData.message}`);
        return false;
      }

      const data = await response.json();
      if (data.success) {
        const { user, token } = data;
        setSession(user, token);

        setIsAuthenticated(true);
        setCurrentUser(user);
        setUserRole(user.role || "guest");

        // console.log("Login successful. Current User:", user);
        // console.log("User Role:", user.role);
        // console.log("isAuthenticated:", true);

        return true;
      }

      alert(`Error: ${data.message}`);
      setError(`Error: ${data.message}`);
      return false;
    } catch (error) {
      console.error("Login error:", error);
      alert("Please your Network Connections!");
      setError("Please check your network connection.");
      clearSession();
      setIsAuthenticated(false);
      setCurrentUser(null);
      setUserRole("guest");

      return false;
    } finally {
      setLoading(false); // Stop loading whether success or failure
    }
  };

  const logout = () => {
    clearSession();
    setIsAuthenticated(false);
    setCurrentUser(null);
    setError("");
    setUserRole("guest");

    console.log("User logged out.");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userRole,
        currentUser,
        loginAuth,
        logout,
        error,
      }}
    >
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
}
