import React, { useState, useRef } from "react";
import "../css1/AddScholarship.css"; // Import the CSS file

const AddScholarship = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    eligibilityCriteria: "",
    amount: "",
    applicationDeadline: "",
    applyUrl: "",
    contactEmail: "",
    contactPhone: "",
    providerName: "",
    providerWebsite: "",
    location: "",
    category: "",
    status: "active", // Default value
  });

  // State to manage error and success messages
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Refs for auto-resizing text areas
  const descriptionRef = useRef(null);
  const eligibilityCriteriaRef = useRef(null);

  // Handle input changes and auto-resize textareas
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Update height of textareas if content changes
    if (name === "description" && descriptionRef.current) {
      descriptionRef.current.style.height = "auto";
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`;
    } else if (
      name === "eligibilityCriteria" &&
      eligibilityCriteriaRef.current
    ) {
      eligibilityCriteriaRef.current.style.height = "auto";
      eligibilityCriteriaRef.current.style.height = `${eligibilityCriteriaRef.current.scrollHeight}px`;
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Basic validation
    if (
      !formData.title ||
      !formData.description ||
      !formData.applicationDeadline
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    // Submit data to API or perform other actions here
    // ...

    // Example success handling
    setSuccess("Scholarship added successfully!");

    // Reset form data after submission (optional)
    setFormData({
      title: "",
      description: "",
      amount: "",
      eligibilityCriteria: "",
      applicationDeadline: "",
      applyUrl: "",
      contactEmail: "",
      contactPhone: "",
      providerName: "",
      providerWebsite: "",
      location: "",
      category: "",
      status: "active",
    });
  };

  return (
    <div className="add-scholarship-form">
      <h2>Add New Scholarship</h2>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <form onSubmit={handleSubmit}>
        <label htmlFor="title" name="title">
          Title:
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
        />

        <label htmlFor="description" name="description">
          Description:
        </label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          ref={descriptionRef} // Assign ref to the textarea
        />

        <label htmlFor="eligibilityCriteria" name="eligibilityCriteria">
          Eligibility Criteria:
        </label>
        <textarea
          id="eligibilityCriteria"
          name="eligibilityCriteria"
          value={formData.eligibilityCriteria}
          onChange={handleChange}
          ref={eligibilityCriteriaRef} // Assign ref to the textarea
        />

        {/* Other form fields... */}

        <label htmlFor="amount" name="amount">
          Amount:
        </label>
        <input
          type="number"
          step="0.01"
          id="amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
        />

        <label htmlFor="applicationDeadline" name="applicationDeadline">
          Application Deadline:
        </label>
        <input
          type="date"
          id="applicationDeadline"
          name="applicationDeadline"
          value={formData.applicationDeadline}
          onChange={handleChange}
          required
        />

        <label htmlFor="applyUrl" name="applyUrl">
          Application URL:
        </label>
        <input
          type="text"
          id="applyUrl"
          name="applyUrl"
          value={formData.applyUrl}
          onChange={handleChange}
        />

        <label htmlFor="contactEmail" name="contactEmail">
          Contact Email:
        </label>
        <input
          type="email"
          id="contactEmail"
          name="contactEmail"
          value={formData.contactEmail}
          onChange={handleChange}
        />

        <label htmlFor="contactPhone" name="contactPhone">
          Contact Phone:
        </label>
        <input
          type="tel"
          id="contactPhone"
          name="contactPhone"
          value={formData.contactPhone}
          onChange={handleChange}
        />

        <label htmlFor="providerName" name="providerName">
          Provider Name:
        </label>
        <input
          type="text"
          id="providerName"
          name="providerName"
          value={formData.providerName}
          onChange={handleChange}
        />

        <label htmlFor="providerWebsite" name="providerWebsite">
          Provider Website:
        </label>
        <input
          type="text"
          id="providerWebsite"
          name="providerWebsite"
          value={formData.providerWebsite}
          onChange={handleChange}
        />

        <label htmlFor="location" name="location">
          Location:
        </label>
        <input
          type="text"
          id="location"
          name="location"
          value={formData.location}
          onChange={handleChange}
        />

        <label htmlFor="category" name="category">
          Category:
        </label>
        <input
          type="text"
          id="category"
          name="category"
          value={formData.category}
          onChange={handleChange}
        />

        <label htmlFor="status" name="status">
          Status:
        </label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="expired">Expired</option>
        </select>

        <button type="submit">Add Scholarship</button>
      </form>
    </div>
  );
};

export default AddScholarship;
