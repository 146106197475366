import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "../css1/LoginUser.css";
import Navbar from "../../components/inc/Navbar";

function LoginUser() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const { loginAuth, isAuthenticated, currentUser, error } = useAuth(); // Get login function and state from AuthContext

  useEffect(() => {
    if (isAuthenticated) {
      // Role-based navigation after successful login
      const userRole = currentUser ? currentUser.role : null;

      if (userRole === "admin") {
        navigate("/back/1admin/adashboard");
      } else if (userRole === "user") {
        navigate("/back/1user/udashboard");
      } else {
        setLoginError(
          "Role not recognized or missing. Please contact support."
        );
        console.error("Unrecognized role or role is missing:", userRole);
        navigate("/default");
      }
    }
  }, [isAuthenticated, currentUser, navigate]); // Dependency on authentication and current user

  useEffect(() => {
    if (error) {
      setLoginError(error);
    }
  }, [error]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // Reset previous login error
    setLoginError("");

    // Basic validation
    if (!email || !password) {
      setLoginError("Both fields are required.");
      return;
    }

    // Attempt login
    try {
      const isLoginSuccessful = await loginAuth(email, password); // Call login function from context
      if (!isLoginSuccessful) {
        setLoginError("An error occurred during login.");
      } else {
        const userRole = currentUser ? currentUser.role : null;
        alert(`Successfully Logged in as ${currentUser.role}`);

        // Redirect based on user role
        if (userRole === "admin") {
          navigate("/back/1admin/adashboard");
        } else if (userRole === "user") {
          navigate("/back/1user/udashboard");
        } else {
          setLoginError(
            "Role not recognized or missing. Please contact support."
          );
          console.error("Unrecognized role or role is missing:", userRole);
          navigate("/default");
        }
      }
    } catch (error) {
      setLoginError("An error occurred during login.");
    }
  };

  const handleOnInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <div>
      <Navbar />

      <div className="user-login-page">
        <div className="login-header">
          <h2>Login</h2>
        </div>
        <div className="user-login-form">
          <form onSubmit={handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={email}
                onChange={handleOnInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                value={password}
                onChange={handleOnInputChange}
                required
              />
            </div>
            {/* Display error message if there is an error */}
            {loginError && <p className="error-message">{loginError}</p>}
            <button type="submit" name="btn_submit" className="btn btn-primary">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginUser;
