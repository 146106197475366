import React from "react";

import UserSidebarMenu from "./UserSidebarMenu";
import "../css1/AdminDashboard.css";
import Header from "../Header";

function UserDashboard() {
  return (
    <div>
      <Header />

      <div className="dashboard-container">
        {/* Sidebar Menu on the left */}
        <UserSidebarMenu />

        {/* Main Content Section */}
        <div className="dashboard-main-content">
          {/* <h1 className="dashboard-title">Dashboard</h1> */}

          {/* Dashboard Cards Section */}
          <div className="dashboard-cards">
            <div className="card">
              <h3>Total Companies</h3>
              <p className="card-value">120</p>
            </div>
            <div className="card">
              <h3>Total Jobs</h3>
              <p className="card-value">345</p>
            </div>
            <div className="card">
              <h3>New Applications</h3>
              <p className="card-value">45</p>
            </div>
          </div>

          {/* Recent Activity Section */}
          <div className="recent-activity">
            <h2>Recent Activity</h2>
            <ul>
              <li>Company "Tech Corp" added a new job listing.</li>
              <li>Job "Software Engineer" updated by "Innovate Ltd".</li>
              <li>New application received for "Project Manager" position.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
