import React, { useState, useEffect } from "react";
import "../../css/FeedbackForm.css";
import Navbar from "../../components/inc/Navbar";

import { Link } from "react-router-dom";
import Footer from "../../components/inc/Footer";

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    comment: "",
    rating: 1,
  });
  const [status, setStatus] = useState("");
  const [submittedFeedback, setSubmittedFeedback] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchFeedback();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("");
    setErrors({});

    // Form Validation
    let isValid = true;
    let formErrors = {};

    if (!feedback.name) {
      isValid = false;
      formErrors.name = "Name is required.";
    }

    if (!feedback.email) {
      isValid = false;
      formErrors.email = "Email is required.";
    }

    if (!feedback.comment) {
      isValid = false;
      formErrors.comment = "Comment is required.";
    }

    if (!feedback.rating || feedback.rating < 1 || feedback.rating > 5) {
      isValid = false;
      formErrors.rating = "Rating should be between 1 and 5.";
    }

    if (!isValid) {
      setErrors(formErrors);
      return;
    }

    try {
      // const response = await fetch(
      //   "http://localhost/web/react/www.jobsfair.info/api/api/feedback/submit_feedback.php",

        const response = await fetch(
          "https://jobsfair.info/api/api/feedback/submit_feedback.php",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedback),
        }
      );

      const data = await response.json();

      if (data.success) {
        setStatus(data.message || "Feedback submitted successfully!");
        setFeedback({ name: "", email: "", comment: "", rating: 1 });
        fetchFeedback();
      } else {
        setStatus(
          <span>
            {data.message || "Failed to submit feedback."}{" "}
            <Link to="/pages/subscriptions">Click here to subscribe.</Link>
          </span>
        );
      }
    } catch (error) {
      setStatus("An API error occurred. Please try again later.");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFeedback((prev) => ({ ...prev, [name]: value }));
  };

  const fetchFeedback = async () => {
    try {
      // const response = await fetch(
      //   "http://localhost/web/react/www.jobsfair.info/api/api/feedback/get_feedback.php"
      // );
       const response = await fetch(
        "https://jobsfair.info/api/api/feedback/get_feedback.php"
      );


      const data = await response.json();

      if (data.success) {
        setSubmittedFeedback(data.feedbacks);
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="feedback-container">
        <h2>We Value Your Feedback</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={feedback.name}
              onChange={handleChange}
              required
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={feedback.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>

          <div className="form-group">
            <label>Rating</label>
            <select
              name="rating"
              value={feedback.rating}
              onChange={handleChange}
              required
            >
              {[1, 2, 3, 4, 5].map((rating) => (
                <option key={rating} value={rating}>
                  {rating}
                </option>
              ))}
            </select>
            {errors.rating && <p className="error">{errors.rating}</p>}
          </div>

          <div className="form-group">
            <label>Comment</label>
            <textarea
              name="comment"
              value={feedback.comment}
              onChange={handleChange}
              required
            />
            {errors.comment && <p className="error">{errors.comment}</p>}
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
          {status && <p className="status-message">{status}</p>}
        </form>

        <h3>Submitted Feedback:</h3>
        <div className="submitted-feedback">
          {submittedFeedback.length > 0 ? (
            submittedFeedback.map((item, index) => (
              <div key={index} className="feedback-item">
                <p>
                  <strong>Name:</strong> {item.name}
                </p>
                <p>
                  <strong>Email:</strong> {item.email}
                </p>
                <p>
                  <strong>Rating:</strong> {item.rating}
                </p>
                <p>
                  <strong>Comment:</strong> {item.comment}
                </p>
              </div>
            ))
          ) : (
            <p>No feedback submitted yet.</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FeedbackForm;
