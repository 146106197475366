import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Header";
// import SidebarMenu from "../SidebarMenu";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import "../css1/DeleteJobItem.css";

const DeleteCompanyItem = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [company, setCompany] = useState(null); // State to store company information

  // Fetch company information on component mount
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        // For Server API
        const response = await fetch(
          `https://jobsfair.info/api/api/company/get_company.php?companyId=${companyId}`
        );

        //const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/company/get_company.php?companyId=${companyId}`);

        if (!response.ok) {
          throw new Error("Failed to fetch company details");
        }

        const data = await response.json();
        setCompany(data); // Set company data to state
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCompany();
  }, [companyId]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this company?"
    );

    if (confirmDelete) {
      try {
        // For Server API
        const response = await fetch(
          `https://jobsfair.info/api/api/company/delete_company.php?companyId=${companyId}`,
          {
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/company/delete_company.php?companyId=${companyId}`,
        //   {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ companyId }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to delete company");
        }

        alert("Company deleted successfully!");
        setSuccess("Company deleted successfully!");
        navigate("/back/companies"); // Navigate to the companies page after deletion
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Company deletion canceled.");
    }
  };

  return (
    <div className="jobs-delete-item-page">
      <Header />
      <div className="jobs-delete-container">
        {/* <SidebarMenu /> */}
        <AdminSideBarMenu />
        <div className="jobs-item-content">
          <h2>Delete Company ID: {companyId}</h2>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>
          {company ? (
            <div>
              <h3>Company Name: {company.name}</h3>
              <p>Company ID: {company.id}</p>
              <p>Address: {company.address}</p>
              <p>Contact Email: {company.contact_email}</p>
              <p>Contact Phone: {company.contact_phone}</p>
              <p>Website: {company.website}</p>
              <button onClick={handleDelete} className="delete-btn">
                Confirm Delete
              </button>
            </div>
          ) : (
            <p>Loading company information...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteCompanyItem;
