import React from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "./pages/landing/Landing";
// import Whoops404 from "./front/Whoops404";
// import JobDetails from "./jobs/JobDetails";
import Footer from "./components/inc/Footer";
import "./App.css";

import { useEffect } from "react";
import HomeScholarships from "./pages/scholarships/HomeScholarships";

function App() {
  useEffect(() => {
    document.title = "Home - jobsfair.info";
  }, []);

  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/pages/scholarships" element={<HomeScholarships />} />
        {/* <Route path="/jobs/:id" element={<JobDetails />} />
        <Route path="*" element={<Whoops404 />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
