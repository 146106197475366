import React from "react";
import "../../css/search-form-container.css";

function SearchForm({ onSearch }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const searchQuery = event.target.elements.search.value;
    const category = event.target.elements.category.value;

    // Pass the searchQuery and category to the parent component
    onSearch(searchQuery, category);
  };

  return (
    <div className="searchform-container">
      <div className="search-side-panel1">
        {/* Filter options can go here */}
      </div>

      <div className="search-main-form">
        <div className="searchform-info">
          <form onSubmit={handleSubmit} className="searchForm">
            <div className="selectCategory">
              <select name="category">
                <option value="">ALL</option>
                <option value="it">IT</option>
                <option value="marketing">Marketing</option>
                <option value="finance">Finance</option>
              </select>
            </div>

            <div className="inputSearch">
              <input type="text" name="search" placeholder="Search for a job" />
            </div>

            <div className="searchButton">
              <button type="submit">Search</button>
            </div>
          </form>
        </div>
      </div>

      <div className="search-side-panel2">
        {/* Additional information or ads can go here */}
      </div>
    </div>
  );
}

export default SearchForm;
