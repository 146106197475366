import ScholarshipsList from "./ScholarshipsList";

import Navbar from "../../components/inc/Navbar";
import { useEffect } from "react";
import Footer from "../../components/inc/Footer";

function HomeScholarships() {
  useEffect(() => {
    document.title = "Scholarships - jobsfair.info";
  }, []);

  return (
    <div>
      <Navbar />

      {/* <SearchForm /> */}
      {/* <hr></hr> */}

      <ScholarshipsList />

      <Footer />
    </div>
  );
}

export default HomeScholarships;
