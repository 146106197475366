import React, { useState, useEffect } from "react";
import Navbar from "../../components/inc/Navbar";
import SearchForm from "./SearchForm";
import { Link, useNavigate } from "react-router-dom";
import "../../css/landing.css";
import parse from "html-react-parser";
// import Navbar1 from "../../components/inc/Navbar1";

function Landing() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [availableJobs, setAvailableJobs] = useState([]);

  useEffect(() => {
    document.title = "Home - jobsfair.info";
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          "https://jobsfair.info/api/api/jobs/get_jobs.php"
        );
        // const response = await fetch(
        //   "http://localhost/web/react/www.jobsfair.info/api/api/jobs/get_jobs.php"
        // );
        if (!response.ok) {
          throw new Error("Failed to fetch jobs");
        }
        const data = await response.json();

        // Flatten the data: Extract all jobs from the nested structure
        // const flatJobs = data.data.flatMap(company => company.jobs);
        const flatJobs = data.data.flatMap((company) =>
          company.jobs.map((job) => ({
            ...job, // Spread the job details
            company_name: company.company_name, // Add company_name to each job
          }))
        );

        // console.log(flatJobs);
        setAvailableJobs(flatJobs || []); // Assuming `jobs` is the key in the API response
        setIsLoading(false);
      } catch (error) {
        // console.error("Error fetching jobs:", error);
        setIsLoading(false);
      }
    };
    fetchJobs();
  }, []);

  const handleSearch = (searchQuery, selectedCategory) => {
    navigate("/pages/jobs", {
      state: { searchTerm: searchQuery, category: selectedCategory },
    });
  };
  // Utility function to truncate text
const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};
// Utility function to remove <ul> and <li> tags
const removeBulletPoints = (html) => {
  return html.replace(/<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, "");
};

  return (
    <div className="landing-container">

{/* <Navbar1 /> */}
      <Navbar />
       {/* <hr /> */}
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-content">
          <h1>Find Your Dream Job</h1>
          <p>
            Search thousands of job opportunities and take the next step in your
            career.
          </p>
          <SearchForm onSearch={handleSearch} />
        </div>
      </div>
      {/* <hr /> */}
      {/* Job Listings Section */}
      <div className="job-listings-section">
        <div className="jbf-side-panel1">
          {/* Additional content (if needed) */}
        </div>
        <div className="job-featured-row">
          <h2>Featured Jobs</h2>
          {isLoading ? (
            <p>Loading jobs...</p>
          ) : availableJobs.length > 0 ? (
            <div className="job-row">
              {availableJobs.map((job) => (
                <div key={job.job_id} className="card">
                  <div className="card-header">{job.company_name || "Jobs"}</div>
                  <div className="card-body">
                    <h4 className="card-title">{job.title}</h4>
                    {/* // Inside the map function */}
                    <div className="card-text">
                    {parse(truncateText(removeBulletPoints(
                            job.description || "No description available"
                          ),
                          150))}
                    </div>
                    <Link
                      to={`/pages/jobs/${job.job_id}/details`}
                      className="btn btn-primary"
                    >
                     Click here for Deadlines and View Details
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No jobs available at the moment.</p>
          )}
        </div>
        <div className="jbf-side-panel2">
          {/* Additional content (if needed) */}
        </div>
      </div>
    </div>
  );
}

export default Landing;
