import React from "react";
import "../../css/Footer.css"; // Make sure this path is correct
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>What We Do</h4>
          <p>
            We provide innovative tech solutions and services to empower
            businesses and individuals.
          </p>
        </div>
        <div className="footer-section">
          <h4>Short Links</h4>
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#careers">Careers</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <Link to="/pages/feedback/">Feedback</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contacts</h4>
          <p>Email: jobsfair.info@gmail.com</p>
        </div>
      </div>
      <div className="footer-copyright">
        © 2024 Jobsfair.info All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
