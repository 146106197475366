import React, { useEffect, useState } from "react";
import AdminSideBarMenu from "./AdminSideBarMenu";
import "../css1/AdminDashboard.css";
import Header from "../../back/Header";

function AdminDashboard() {
  const [dashboardData, setDashboardData] = useState({
    totalCompanies: 0,
    totalJobs: 0,
    totalSubscriptions: 0,
    error: null,
  });
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [updateError, setUpdateError] = useState(null);

  // Fetch dashboard data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(
          "https://jobsfair.info/api/api/dashboard/get_dashboard_data.php"
        );
        // const response = await fetch(
        //   "http://localhost/web/react/www.jobsfair.info/api/api/dashboard/get_dashboard_data.php"
        // );

        const data = await response.json();

        if (data.success) {
          setDashboardData((prev) => ({
            ...prev,
            totalCompanies: data.data.total_companies,
            totalJobs: data.data.total_jobs,
            totalSubscriptions: data.data.total_subscriptions,
            error: null,
          }));
        } else {
          setDashboardData((prev) => ({ ...prev, error: data.message }));
        }
      } catch (error) {
        setDashboardData((prev) => ({ ...prev, error: "Error fetching data" }));
      }
    };

    fetchDashboardData();
  }, []);

  // Fetch recent updates
  useEffect(() => {
    const fetchRecentUpdates = async () => {
      try {
        const response = await fetch(
          "https://jobsfair.info/api/api/dashboard/get_recent_updates.php"
        );
        // const response = await fetch(
        //   "http://localhost/web/react/www.jobsfair.info/api/api/dashboard/get_recent_updates.php"
        // );
        const data = await response.json();

        if (data.success) {
          setRecentUpdates(data.data);
        } else {
          setUpdateError(data.message);
        }
      } catch (error) {
        setUpdateError("Error fetching recent updates");
      }
    };

    fetchRecentUpdates();
  }, []); // Empty array ensures this runs once on component mount

  return (
    <div>
      <Header />

      <div className="dashboard-container">
        {/* Sidebar Menu on the left */}
        <AdminSideBarMenu />

        {/* Main Content Section */}
        <div className="dashboard-main-content">
          {/* Dashboard Cards Section */}
          <div className="dashboard-cards">
            <div className="card">
              <h3>Total Companies</h3>
              <p className="card-value">{dashboardData.totalCompanies}</p>
            </div>
            <div className="card">
              <h3>Total Jobs</h3>
              <p className="card-value">{dashboardData.totalJobs}</p>
            </div>
            <div className="card">
              <h3>New Applications</h3>
              <p className="card-value">{dashboardData.totalSubscriptions}</p>
            </div>
          </div>

          {/* Recent Activity Section */}
          <div className="recent-activity">
            <h2>Recent Activity</h2>
            {updateError && <p className="error-message">{updateError}</p>}
            <ul>
              {recentUpdates.length > 0 ? (
                recentUpdates.map((update, index) => (
                  <li key={index}>
                    <strong>
                      {update.entity_type.charAt(0).toUpperCase() +
                        update.entity_type.slice(1)}
                    </strong>{" "}
                    -{update.entity_name} updated at:{" "}
                    {new Date(update.updated_at).toLocaleString()}
                  </li>
                ))
              ) : (
                <li>No recent updates found.</li>
              )}
            </ul>
          </div>

          {/* Recent Activity Section */}
          {/* <div className="recent-activity">
            <h2>Recent Activity</h2>
            <ul>
              <li>Company "Tech Corp" added a new job listing.</li>
              <li>Job "Software Engineer" updated by "Innovate Ltd".</li>
              <li>New application received for "Project Manager" position.</li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
