import { useEffect, useState } from "react";
import AdminSideBarMenu from "../admin/AdminSideBarMenu";
import { useParams } from "react-router-dom";
import Header from "../Header";
import "../css1/EditUserList.css";

function EditUser() {
  const { userId } = useParams();

  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState({
    email: "",
    username: "",
    password: "", // Changed from password to password_hash
    confirmPassword: "",
    status: "active",
    role: "user",
  });
  const [error, setError] = useState(null); // Initialize error as null
  // State to track password visibility
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target; // Correct destructuring
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value, // Dynamically update the user object
    }));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getResponse = await fetch(
          `https://jobsfair.info/api/api/user/get_user.php?userId=${userId}`
        );
        // const getResponse = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/user/get_user.php?userId=${userId}`
        // );
        const thisdata = await getResponse.json();
        if (thisdata.status === "success") {
          setUser(thisdata.data); // Assuming `thisdata.data` contains the user object
        } else {
          setError("User not found or API error");
        }
      } catch (error) {
        console.log(error);
        setError("Error fetching user data");
      }
    };

    fetchUserData();
  }, [userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here you can handle form submission, e.g., sending data to an API
    if (user.password !== user.confirmPassword) {
      setError("Passwords do not match!");
      alert("Passwords do not match!");
      return;
    }
    try {
      const updateUser = await fetch(
        `https://jobsfair.info/api/api/user/update_user.php?userId=${userId}`,{

      // const updateUser = await fetch(
      //   `http://localhost/web/react/www.jobsfair.info/api/api/user/update_user.php?userId=${userId}`,

      //   {
          method: "POST",
          Headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            userId,
            password: user.password, // Use password_hash here
            email: user.email,
            username: user.username,
            status: user.status,
            role: user.role,
          }),
        }
      );
      const UserDataResult = await updateUser.json();
      if (UserDataResult.success) {
        alert("Company updated successfully!");
        setSuccess("Company updated successfully!");
      } else {
        setError(UserDataResult.message || "An error occurred");
        alert("Failed to update Company!", UserDataResult.message);
        throw new Error(UserDataResult.message || "Failed to update Company");
      }
    } catch (error) {
      console.log(error);
      setError("An API error occurred while updating the user.");
      alert("An API error occurred while updating the user.");
    }
  };

  return (
    <div>
      <Header />
      <div className="users-container">
        <AdminSideBarMenu />
        <div className="Users-page">
          <h1>Edit User for: {user.name}</h1>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>

          <div className="registration-card" tabIndex="1">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={user.username}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"} // Change input type based on visibility state
                    id="password"
                    name="password" // Use password_hash here
                    value={user.password}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="password-toggle-button"
                  >
                    {passwordVisible ? "Hide" : "Show"}{" "}
                    {/* Button text for toggle */}
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="confirmPassword">ConfirmPassword:</label>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={confirmPasswordVisible ? "text" : "password"} // Change input type based on visibility state
                    id="confirmPassword"
                    name="confirmPassword"
                    value={user.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="password-toggle-button"
                  >
                    {confirmPasswordVisible ? "Hide" : "Show"}{" "}
                    {/* Button text for toggle */}
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="status">Status:</label>
                <select
                  id="status"
                  name="status"
                  value={user.status}
                  onChange={handleChange}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="role">Role:</label>
                <select
                  id="role"
                  name="role"
                  value={user.role}
                  onChange={handleChange}
                >
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="guest">Guest</option>
                </select>
              </div>

              <button type="submit">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
