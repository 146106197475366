import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../jobs/Loading";
import Error from "../jobs/Error";
import Navbar from "../../components/inc/Navbar";
import Footer from "../../components/inc/Footer";
// import SearchForm from "../landing/SearchForm";
import { Link } from "react-router-dom";
import "../../css/SingleJobDetails.css";
import "../../css/JobsList.css";
import parse from "html-react-parser";

function JobDetails() {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://jobsfair.info/api/api/jobs/get_job.php?jobId=${jobId}`
        );

        if (!response.ok) {
          setError("HTTP error!");
        } else {
          const data = await response.json();
          setJob(data);
        }
      } catch (error) {
        setError("API is not available");
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error} />;
  }

  if (!job) {
    return <Error message="Job not found" />;
  }

  return (
    <div className="job-details">
      <Navbar />
      {/* <SearchForm /> */}
      {/* <hr className="border-custom no-padding-margin" /> */}
      <div className="jobs-list-container">
        <div className="jobs-side-panel1">
          <div className="filter-options">
            {/* Additional filtering options */}
          </div>
        </div>
        <div className="jobs-main-container">
          <div className="jobs-detail-container">
            <div className="jobs-info mycard">
              <h1>Job Details: {job.title} </h1>
              <hr className="border-custom no-padding-margin" />
              {job.company_name && (
                <div>
                  <strong>Company Name:</strong> {parse(job.company_name)}
                </div>
              )}
              {job.title && (
                <div>
                  <strong>Job Title: </strong> {parse(job.title)}
                </div>
              )}
              {job.updated_at && (
                <div>
                  <strong>Updated At:</strong> {job.updated_at}
                </div>
              )}
              {job.deadline && (
                <div>
                  <strong>Deadline:</strong> {job.deadline}
                </div>
              )}
              {job.department && (
                <div>
                  <strong>Job Department: </strong> {job.department}
                </div>
              )}
              {job.location && (
                <div>
                  <strong>Job Location: </strong> {parse(job.location)}
                </div>
              )}

              {job.description && (
                <div className="justify-text">
                  <strong>Description: </strong> {parse(job.description)}
                </div>
              )}
              {job.requirement && (
                <div className="justify-text">
                  <strong>Requirement: </strong> {parse(job.requirement)}
                </div>
              )}

              {job.experiences && (
                <div className="justify-text">
                  <strong>Experiences: </strong> {parse(job.experiences)}
                </div>
              )}

              {job.responsibility && (
               <div className="justify-text">
                  <strong>Responsibility: </strong> {parse(job.responsibility)}
                </div>
              )}

              {job.qualification && (
                <div className="justify-text">
                  <strong>Qualification: </strong> {parse(job.qualification)}
                </div>
              )}

              {job.applyUrl && (
                <div>
                  <strong>Apply Link: </strong>
                  <ul className="job-details-list">
                    <li>
                      <Link
                        to={job.applyUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="job-apply-link"
                      >
                        {job.applyUrl}
                      </Link>
                    </li>
                  </ul>
                </div>
              )}

              <div className="pagination">
                {/* {renderPaginationControls()} */}
              </div>
            </div>
          </div>
        </div>
        <div className="jobs-side-panel2">
          <div className="additional-info">
            {/* Additional information or ads can go here */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JobDetails;