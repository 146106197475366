import React, { useState } from "react";
import "./css1/Header.css";
import { useAuth } from "./auth/AuthContext";
import bedasa from "../assets/bedasa.JPG"; // Adjust the path as needed


const Header = () => {
  const { isAuthenticated, currentUser, logout } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Handle logout action
  const handleLogout = () => {
    logout();
  };

  // If user is not authenticated, show login message
  if (!isAuthenticated) {
    return <p>Please log in to access this page.</p>;
  }

  return (
    <header className="admin-header">
      <div className="header-container">
        <h1 className="dashboard-title">Admin Dashboard</h1>

        <div className="user-profile">
          <span className="user-name">
            Welcome, {currentUser?.name} ({currentUser?.role || "Admin"})
          </span>

          <div className="profile-container">
            <img
              src={bedasa}
              alt="User Profile"
              className="profile-pic"
              onClick={toggleDropdown}
              aria-label="User Profile"
            />
            {dropdownVisible && (
              <div className="profile-dropdown">
                <ul>
                  <li>Profile</li>
                  <li>Settings</li>
                  <li onClick={handleLogout}>Logout</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
