import React, { useState } from "react";
import "../css1/AdminSidebarMenu.css";
import { Link } from "react-router-dom";

function UserSidebarMenu() {
  const [isPagesOpen, setIsPagesOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
  const [isJobsOpen, setIsJobsOpen] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  function handleToggle(section) {
    if (section === "pages") {
      setIsPagesOpen(!isPagesOpen);
    } else if (section === "settings") {
      setIsSettingsOpen(!isSettingsOpen);
    } else if (section === "companies") {
      setIsCompaniesOpen(!isCompaniesOpen);
    } else if (section === "jobs") {
      setIsJobsOpen(!isJobsOpen);
    } else if (section === "users") {
      setIsUsersOpen(!isUsersOpen);
    } else if (section === "notification") {
      setIsNotificationOpen(!isNotificationOpen);
    }
  }

  return (
    <div>
      <div className="back-sidebar">
        <h3>Admin Menu</h3>
        <div className="back-sidebar-content">
          {/* Pages Section */}
          <div className={`back-section ${isPagesOpen ? "active" : ""}`}>
            <button
              className={`toggle-btn ${isPagesOpen ? "active" : ""}`}
              onClick={() => handleToggle("pages")}
            >
              Pages
            </button>
            {isPagesOpen && (
              <ul>
                <li>
                  <Link to="/back/1user/udashboard">Dashboard</Link>
                </li>
              </ul>
            )}
          </div>

          {/* Companies Section */}
          <div className={`back-section ${isCompaniesOpen ? "active" : ""}`}>
            <button
              className={`toggle-btn ${isCompaniesOpen ? "active" : ""}`}
              onClick={() => handleToggle("companies")}
            >
              Companies
            </button>
            {isCompaniesOpen && (
              <ul>
                <li>
                  <Link to="/back/add-company">Add Company</Link>
                </li>
                <li>
                  <Link to="/back/companies">Manage Companies</Link>
                </li>
              </ul>
            )}
          </div>

          {/* Jobs Section */}
          <div className={`back-section ${isJobsOpen ? "active" : ""}`}>
            <button
              className={`toggle-btn ${isJobsOpen ? "active" : ""}`}
              onClick={() => handleToggle("jobs")}
            >
              Jobs
            </button>
            {isJobsOpen && (
              <ul>
                <li>
                  <Link to="/back/jobs/add-jobs">Add Job</Link>
                </li>
                <li>
                  <Link to="/back/jobs">Manage Jobs</Link>
                </li>
              </ul>
            )}
          </div>

          {/* Users Section */}
          <div className={`back-section ${isUsersOpen ? "active" : ""}`}>
            <button
              className={`toggle-btn ${isUsersOpen ? "active" : ""}`}
              onClick={() => handleToggle("users")}
            >
              Users
            </button>
            {isUsersOpen && (
              <ul>
                <li>
                  <Link to="/back/users/add-user">Add User</Link>
                </li>
                <li>
                  <Link to="/back/users/">Manage Users</Link>
                </li>
              </ul>
            )}
          </div>

          {/* Settings Section */}
          <div className={`back-section ${isSettingsOpen ? "active" : ""}`}>
            <button
              className={`toggle-btn ${isSettingsOpen ? "active" : ""}`}
              onClick={() => handleToggle("settings")}
            >
              Settings
            </button>
            {isSettingsOpen && (
              <ul>
                <li>
                  <Link to="/settings">General Settings</Link>
                </li>
                <li>
                  <Link to="/profile">Profile Settings</Link>
                </li>
                <li>
                  <Link to="/security">Security</Link>
                </li>
              </ul>
            )}
          </div>

          {/* Notification Section */}
          <div className={`back-section ${isNotificationOpen ? "active" : ""}`}>
            <button
              className={`toggle-btn ${isNotificationOpen ? "active" : ""}`}
              onClick={() => handleToggle("notification")}
            >
              Notification
            </button>
            {isNotificationOpen && (
              <ul>
                <li>
                  <Link to="/back/notification/sendNotification">
                    Send Notification
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSidebarMenu;
