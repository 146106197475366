import React, { useState } from "react";
import { Link } from "react-router-dom";

function CompanyRow({ company }) {
  const [expanded, setExpanded] = useState(false);

  const toggleDetails = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <tr>
        <td>{company.id}</td>
        <td>{company.name}</td>

        <td className="actions">
          <button onClick={toggleDetails} className="details-toggle-btn">
            {expanded ? "Hide Details" : "Show Details"}
          </button>
        </td>
      </tr>
      {expanded && (
        <tr className="details-row">
          <td colSpan="3">
            <table className="company-details-table">
              <tbody>
                <tr>
                  <th style={{ color: "black" }}>Email:</th>
                  <td>{company.contact_email}</td>
                </tr>
                <tr>
                  <th style={{ color: "black" }}>Phone:</th>
                  <td>{company.contact_phone}</td>
                </tr>
                <tr>
                  <th style={{ color: "black" }}>Website:</th>
                  <td>
                    <a
                      href={company.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {company.website}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th style={{ color: "black" }}>Address:</th>
                  <td>{company.address}</td>
                </tr>
                <tr>
                  <th style={{ color: "black" }}>Established:</th>
                  <td>
                    {company.established
                      ? new Date(company.established).toLocaleDateString()
                      : "Not Given"}
                  </td>
                </tr>
                <tr>
                  <th style={{ color: "black" }}>Description:</th>
                  <td>{company.description}</td>
                </tr>
                <tr>
                  <th className="actions">
                    <Link
                      to={`/back/company/edit-company/${company.id}`}
                      className="edit-link"
                    >
                      Edit
                    </Link>
                  </th>

                  <th className="actions">
                    <Link
                      to={`/back/company/delete-company/${company.id}`}
                      className="delete-link"
                    >
                      Delete
                    </Link>
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}

export default CompanyRow;
